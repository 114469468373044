import axios from "axios";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
})

axiosInstance.interceptors.request.use(
    (config) => {
        // const token = localStorage.getItem(`accessToken`)
        // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImhhcmppbmRlckBnbWFpbC5jb20iLCJpZCI6IjY2MmY2MDM1Nzg2NjQzMjViYWYyOTM5MiIsImlzQWRtaW4iOnRydWUsImlhdCI6MTcxODI3NDU0MCwiZXhwIjoxNzE4MzYwOTQwfQ.qXLJ3ms3uo21b5j7JzspASd4whRZCbnb1yCZSC_3J9k'
        // if (token) {
        //     config.headers.Authorization = `Bearer ${token}`;
        // }
        return config;
    },
    (e) => {
        return Promise.reject(e);
    }
)

export default axiosInstance;