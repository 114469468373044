import React, { useState } from "react";
import "./Membership.css"; // Import your CSS styles here
import InnerBanner from "../../Components/InnerBanner/InnerBanner";

const Membership = () => {
  const [india, setIndia] = useState(true); // State to manage the toggle

  const toggleSubscription = (type) => {
    setIndia(type === "india"); // Update the state based on button clicked
  };

  const paysubmit = (event) => {
    event.preventDefault();
    // Handle payment submission logic here
    console.log("Payment submitted");
  };

  return (
    <>
      <InnerBanner pageName="Membership" />

      {/* <div className="inner-banner"> */}

      <section className="section">
        <div className="container subscription-container">
          <div className="row mb-4">
            <div className="col-12">
              <div className="subscription-header">
                <h2>Membership</h2>
                <div className="toggle-switch">
                  <button
                    className={`toggle-button ${india ? "active" : ""}`}
                    onClick={() => toggleSubscription("india")}
                  >
                    India
                  </button>
                  <button
                    className={`toggle-button ${!india ? "active" : ""}`}
                    onClick={() => toggleSubscription("international")}
                  >
                    International
                  </button>
                </div>
              </div>
            </div>
          </div>

          {india ? (
            <div className="subscription-plans row row-no-gutters">
              {/* Membership Plans for India */}
              {[
                {
                  title: "State Fee",
                  price: "₹24,500",
                  details: [
                    "State Annual Fee - <strong>₹21,000/-</strong>",
                    "Annual renewal fee of - <strong>₹3,500/-</strong>",
                  ],
                },
                {
                  title: "District Fee",
                  price: "₹12,500",
                  details: [
                    "District Annual Fee - <strong>₹11,000/-</strong>",
                    "Annual renewal fee of - <strong>₹1,500/-</strong>",
                  ],
                },
                {
                  title: "Individual National Member",
                  price: "51,000/one-time fee",
                },
                {
                  title: "Individual State Member",
                  price: "21,000/one-time fee",
                },
                {
                  title: "Institutional Members",
                  price: "21,000/one-time fee",
                },
                {
                  title: "Pythian Peace Ambassadors",
                  price: "5,000/one-time fee",
                },
                {
                  title: "Pythian Student Members",
                  price: "₹250",
                  details: [
                    "Includes Digital ID card",
                    "Membership certificate",
                  ],
                },
                {
                  title: "Pythian Youth Membership",
                  price: "₹750",
                  details: [
                    "Includes Pythian T-shirt",
                    "One Pythian coffee mug",
                    "Digital ID card",
                    "Membership certificate",
                  ],
                },
                {
                  title: "National and State Associations and Federations",
                  price: "No Fee",
                },
                {
                  title: "Arts, Cultural, and Sports Organizations",
                  price: "No Fee",
                },
              ].map((plan, index) => (
                <div className="col-12 col-md-6 col-lg-4 mb-4" key={index}>
                  <div className="plan">
                    <h3 className="title-1">{plan.title}</h3>
                    <p className="main-price">
                      <strong>{plan.price}</strong>
                    </p>
                    {/* Check if details exist and render them as a list */}
                    {plan.details && (
                      <ul className="list-group text-center">
                        {plan.details.map((detail, detailIndex) => (
                          <li
                            key={detailIndex}
                            className="list-group-item border-0"
                            dangerouslySetInnerHTML={{ __html: detail }}
                          />
                        ))}
                      </ul>
                    )}
                    <button
                      className="action-button mt-2 mt-md-4 mb-4"
                      onClick={paysubmit}
                    >
                      <span></span>
                      <i>Buy Now</i>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="no-datafound">No Data Found</div>
          )}
        </div>
      </section>
    </>
  );
};

export default Membership;
