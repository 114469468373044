import HomeBG from "../../../assets/images/frontend/Banner.jpg";
import './Banner.css'
import {signupUrl} from '../../../assets/utils/signup';
const Banner = () => {
    return (
        <div className="banner">
            <img className='home-banner-image' src={HomeBG}/>
            <div className="home-banner-content">
                <h1>1st National Pythian Games 2024 Chandigarh</h1>
                <a href={signupUrl}
                   className="action-button mt-2 mt-md-4"><span></span><i>Click to
                    Register</i></a>
            </div>
        </div>
    )
}
export default Banner