import axiosInstance from '../../assets/utils/api';

const upcomingEvents = async () => {
    try {
        const response = await axiosInstance.get(`api/upcoming-events`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default upcomingEvents;