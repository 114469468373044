import EventBanner from '../../../assets/images/frontend/event-banner.png'
import {Link} from "react-router-dom";

const InnerBanner = ({pageName, altText}) => {
    return (
        <>
            <div className="inner-banner">
                <figure>
                    <img src={EventBanner} alt="Event Banner"/>
                </figure>
                <div className=" breadcrumb-wrap">
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link routerLink="/home">Home</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">{pageName}</li>
                        </ol>
                    </nav>
                    <h2>{altText || pageName}</h2>
                </div>
            </div>
        </>
    )
}
export default InnerBanner