import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import upcomingEvents from "../../Apis/upcomingEvents";
import "./EventDetail.css";
import Calender from "../../../assets/images/frontend/calendar.svg";
import Location from "../../../assets/images/frontend/orange-map-pin.svg";
import HomeBG from "../../../assets/images/frontend/event-banner.jpg";
import EventImage from "../../../assets/images/frontend/event-detail-img.png";
import {signupUrl} from '../../../assets/utils/signup';

const EventDetails = () => {
    const {id} = useParams();
    const [eventDetail, setEventDetail] = useState(null);
    const [error, setError] = useState(null);
    const [date, setDate] = useState("");
    const [countdown, setCountdown] = useState({
        days: 0, hours: 0, minutes: 0, seconds: 0,
    });

    useEffect(() => {
        const fetchEventDetail = async () => {
            try {
                const response = await upcomingEvents();
                const selectedEvent = response.data.eventDetails[0];

                if (selectedEvent) {
                    setEventDetail(selectedEvent);
                    const dateString = selectedEvent.start_date;
                    const options = {
                        weekday: "long", year: "numeric", month: "long", day: "numeric",
                    };
                    const formattedDate = new Date(dateString).toLocaleDateString("en-US", options);
                    setDate(formattedDate);
                } else {
                    setError("Event not found.");
                }
            } catch (error) {
                console.error("Error fetching event details:", error);
                setError("Failed to load event details.");
            }
        };

        fetchEventDetail();
    }, [id]);

    useEffect(() => {
        const calculateCountdown = () => {
            if (!eventDetail) return;

            const targetDate = new Date(eventDetail.start_date).getTime();
            const now = new Date().getTime();
            const difference = targetDate - now;

            if (difference < 0) {
                setCountdown({days: 0, hours: 0, minutes: 0, seconds: 0});
                return;
            }

            setCountdown({
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
                minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
                seconds: Math.floor((difference % (1000 * 60)) / 1000),
            });
        };

        const timer = setInterval(calculateCountdown, 1000);

        return () => clearInterval(timer);
    }, [eventDetail]);

    if (error) {
        return <div>{error}</div>;
    }

    if (!eventDetail) {
        return <div>Loading...</div>;
    }

    return (<>
        <div className="inner-banner large">
            <figure>
                <img src={HomeBG} alt="Event Banner"/>
            </figure>
            <div className="banner-content">
                <div className="inner-banner-txt">
                    <h1>{eventDetail.name}</h1>
                    <div className="meta-data mt-2">
                        <ul>
                            <li>
                                <img src={Calender} alt="Calendar"/>
                                <span>{date}</span>
                            </li>
                            <li>
                                <img src={Location} alt="Location"/>
                                <span>{eventDetail.venue_partner}</span>
                            </li>
                        </ul>
                    </div>
                    <a
                        href={signupUrl}
                        className="action-button mt-3 mt-md-5"
                    >
                        <span></span>
                        <i>Register Now</i>
                    </a>
                </div>
            </div>
        </div>

        <section className="about section" style={{animationDelay: "200ms"}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6" style={{animationDelay: "2s"}}>
                        <h1 className="mb-4 lh-base titleFont title">
                            Join Us for the 1st National Pythian Games 2024!
                        </h1>
                        <p>
                            We are excited to announce the 1st National Pythian Games 2024,
                            a historic event celebrating culture and tradition like never
                            before!
                        </p>
                        <p>
                            This is the first-ever cultural games event in the world, and we
                            are proud to host it at {""}:
                        </p>
                        <div className="location-detail mb-3">
                            <table>
                                <tr>
                                    <td className='fw-bold'>Location:</td>
                                    <td className='fw-bold'>Tau Devi Lal Stadium, Panchkula, Haryana, from 27th to
                                        30th September
                                        2024.
                                    </td>
                                </tr>
                                <tr>
                                    <td className='fw-bold'>Registration Open:</td>
                                    <td className='fw-bold'> 1st June 2024</td>
                                </tr>
                                <tr>
                                    <td className='fw-bold'>Registration Closes:</td>
                                    <td className='fw-bold'>15th August 2024</td>
                                </tr>
                            </table>
                            <p className="mb-0">
                                <b>Calling All Artists and Players!</b>
                            </p>
                            <p className="mb-0">
                                Whether you are an artist or a player, you can register in
                                your field of participation if it is mentioned in our list.
                                Don’t miss this chance to showcase your talent on a national
                                platform.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6 text-center">
                        <div className="about-img">
                            <div className="about-card2">
                                <img className="w-100" src={EventImage} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="counter-section">
            <div className="container">
                <div className="counter-wrap">
                    <h2>Count Every Second Until the Games</h2>
                    <div className="live-clock">
                        <ul>
                            <li>
                                <b>{countdown.days}</b>
                                <span>days</span>
                            </li>
                            <li>
                                <b>{countdown.hours}</b>
                                <span>hours</span>
                            </li>
                            <li>
                                <b>{countdown.minutes}</b>
                                <span>minutes</span>
                            </li>
                            <li>
                                <b>{countdown.seconds}</b>
                                <span>seconds</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <section className="section">
            <div className="container">
                <h1 className="mb-4 lh-base titleFont title text-center">
                    Event Related Categories
                </h1>
                <div className="row">
                    <div className="eventCategoryList">Arts</div>
                    <div className="eventCategoryList">Martial Arts</div>
                    <div className="eventCategoryList">Traditional Games</div>
                    <div className="eventCategoryList">Create Your Own event</div>
                </div>
            </div>
        </section>
    </>);
};

export default EventDetails;
