import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import InnerBanner from '../../Components/InnerBanner/InnerBanner';

const PartnerDetails = () => {
    const { id } = useParams(); // Get the id from the URL parameters
    const [data, setData] = useState({}); // Start with an empty object
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchDetails = async () => {
            try {
                const response = await axios.get(`https://reg.pythiangames.org/api/partners/${id}`);
                // Assuming the data is under response.data.partners
                if (response.data && response.data.partners) {
                    setData(response.data.partners); // Set the partners data
                } else {
                    setError("No details found for this entry.");
                }
            } catch (error) {
                console.error("Error fetching details:", error);
                setError("Failed to fetch details. Please try again later.");
            } finally {
                setIsLoading(false);
            }
        };

        fetchDetails();
    }, [id]); // Run this effect when the id changes

    // Loading state
    if (isLoading) {
        return <p>Loading...</p>;
    }

    // Error handling
    if (error) {
        return (
            <div className="error-message text-center">
                <h2>Error</h2>
                <p>{error}</p>
            </div>
        );
    }

    // Check if data is empty
    if (!data || Object.keys(data).length === 0) {
        return (
            <div className="no-data-message text-center">
                <h2>No Details Available</h2>
                <p>We couldn't find any details for this entry.</p>
            </div>
        );
    }

    // Construct the image URL
    const imageUrl = `https://reg.pythiangames.org/${data.src?.replace(/\\/g, "/")}`;

    // Render the details here
    return (
        <>
            <InnerBanner pageName={"Partner Information"} />
            <section className="cultural section">
                <div className="container">
                    <div className="row g-4 mb-4">
                        <div className="col-md-4 col-lg-3 col-12">
                            <div className="">
                                <img src={imageUrl} className="w-100" alt={data.name || 'Partner Image'} />
                            </div>
                        </div>
                        <div className="col-md-8 col-lg-9 col-12">
                            <div className="">
                                <div className="">
                                    <h2 className="mt-12">{data.name }</h2>
                                    <p><b>{data.profile }</b></p>
                                    <p><b>Country: {data.country }</b></p>
                                    <p>{data.description }</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PartnerDetails;
